.td {
	.green-bg,
	.yellow-bg,
	.red-bg,
	.pending-status,
	.pending_signing-status,
	.failed-status,
	.rejected-status,
	.cancelled-status,
	.completed-status,
	.success-status,
	.broadcasting-status,
	.admin_pending-status,
	.processing-status {
		border: 1px solid red;
		border-radius: 20px;
		background: transparent;
		padding: 4px 6px;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		@media screen and (max-width: 1199px) {
			width: 120px;
		}
	}
	.yellow-bg,
	.pending-status,
	.pending_signing-status,
	.admin_pending-status,
	.processing-status {
		border-color: var(--additional-warning);
		background: var(--transparent-yellow);
		color: var(--additional-warning);
	}

	.red-bg,
	.failed-status,
	.rejected-status,
	.cancelled-status {
		border-color: var(--additional-error);
		background: var(--transparent-red);
		color: var(--additional-error);
	}

	.green-bg,
	.completed-status,
	.success-status {
		border-color: var(--additional-green);
		background: var(--transparent-green);
		color: var(--additional-green);
	}

	.blue-bg,
	.broadcasting-status {
		border-color: var(--primary-blue-400);
		background: var(--primary-blue-50);
		color: var(--primary-blue-400);
	}
}

.status {
	color: var(--greyscale-grey-800);
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	padding: 4px 6px;
	min-width: 88px;
	border-radius: 20px;
	border: 0.8px solid var(--greyscale-grey-800);
	background: var(--transparent-green);
	display: inline-flex;
	align-items: center;
	justify-content: center;

	&--accepted {
		border-color: var(--additional-green);
		background: var(--transparent-green);
		color: var(--additional-green);
	}

	&--rejected {
		border-color: var(--additional-error);
		background: var(--transparent-red);
		color: var(--additional-error);
	}

	&--new {
		border-color: var(--primary-blue-400);
		background: var(--primary-blue-50);
		color: var(--primary-blue-400);
	}
}

