.account-details {
	.input-item--user-data {
		width: 100%;
	}
	/*.tabs-account {
		margin: 24px;
		a {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			height: 30px;
			padding: 0px 12px;
			border-radius: 30px;
			border: 1px solid;
			color: rgba(119, 126, 144, 1);
			margin-right: 8px;
			margin-bottom: 10px;

			font-family: Euclid Circular A !important;
			font-size: 14px !important;
			font-weight: 400 !important;
			line-height: 22px !important;
			letter-spacing: 0px !important;
			text-align: center !important;

		}

		a.active {
			background: rgba(33, 44, 99, 1);
			color: white;
		}
	}*/
}
.user-management-wrap {
	border-radius: 14px;
	border: 1px solid var(--gray100);
	background: #ffffff;
	padding: 24px;
}
.account {
	.error-red {
		position: relative;
		bottom: 12px;
	}
	&-body {
		margin: 0px;
	}

	&-item {
		margin-bottom: 24px;
		.website {
			position: relative;
			svg {
				position: absolute;
				right: 18px;
				top: 50%;
				transform: translateY(-50%);
			}
		}
		&__title {
			p {
				font-family: 'Euclid Circular A', sans-serif;
				font-size: 24px;
				font-weight: 600;
				line-height: 30px;
				letter-spacing: 0;
				text-align: left;
				margin-bottom: 24px;
				margin-top: 24px;
			}
		}
		&__actions {
			margin-bottom: 10px;
		}
	}

	&-list {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 0 20px;

		&-item {
			width: 100%;

			input {
				width: 100%;
			}

			input:disabled {
				//cursor: not-allowed;
				cursor: default;
			}
		}
	}
	.account-list-item__name {
		font-family: 'Euclid Circular A', sans-serif;
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
		letter-spacing: 0;
		text-align: left;
		margin-bottom: 8px;
		color: rgba(99, 99, 99, 1) !important;
	}
	.account-item__verification___status {
		span {
			margin: 0 8px;
		}
	}
	.account-item__verification {
		display: flex;
		justify-content: space-between;
		button {
			display: flex;
			font-family: Euclid Circular A;
			font-size: 14px;
			font-weight: 500;
			line-height: 22px;
			letter-spacing: 0px;
			text-align: left;
			position: relative;
			color: rgba(0, 113, 171, 1);
			margin-right: 24px;
			svg {
				position: absolute;
				right: -20px;
				top: 48%;
				transform: translateY(-50%);
			}
		}
		.btn-details-up {
			svg {
				transform: rotate(180deg) translateY(50%);
			}
		}
	}
	.account-list-details {
		border-radius: 10px;
		margin-top: 24px;
		.verification-attachment-download {
			margin-left: 16px;
		}
		.verification-attachment {
			display: flex;
			max-width: 100%;
			height: fit-content;
			padding: 0 16px;
			p {
				color: var(--text-color-main);
			}
			.input-file-preview__item {
				cursor: pointer;
			}
		}
	}
	.account-list-item--mb-32 {
		background: #f1f7fc;
		.account-list-item__name {
			padding: 16px;
			p {
				//styleName: Heading/H4;
				font-family: Euclid Circular A;
				font-size: 18px;
				font-weight: 500;
				line-height: 23px;
				letter-spacing: 0px;
				text-align: left;
				color: rgba(20, 20, 20, 1);
			}
		}
	}
	.account-list-item__name {
		p {
			//styleName: Body/Body4 14 Regular;
			font-family: Euclid Circular A;
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
			letter-spacing: 0px;
			text-align: left;
		}
	}
	.account-checkbox {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}
	.select-value,
	.select--selected-value {
		font-family: 'Twemoji Country Flags', 'Euclid Circular A', sans-serif;
	}
	.account-list-item__info {
		.disabled {
			cursor: default;
			background: #f7f7f7;
			border-radius: 10px;
		}
	}
	.select-counter {
		.select {
			height: 44px;
			border: 1px solid var(--border-main-color);
			border-radius: 10px;
		}
	}
}

