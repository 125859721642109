*[class*='--mt-0'] {
	margin-top: 0 !important;
}

*[class*='--mb-0'] {
	margin-bottom: 0 !important;
}

*[class*='--mb-40'] {
	margin-bottom: 40px;
}

*[class*="--gap-4"] {
	gap: 4px !important;
}

*[class*="--gap-40"] {
	gap: 40px !important;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.mb-4 {
	margin-bottom: 4px !important;
}

.mb-8 {
	margin-bottom: 8px !important;
}

.mb-10 {
	margin-bottom: 10px !important;
}

.mb-12 {
	margin-bottom: 12px !important;
}

.mb-16 {
	margin-bottom: 16px !important;
}

.mb-20 {
	margin-bottom: 20px !important;
}

.mb-30 {
	margin-bottom: 30px !important;
}

.mb-35 {
	margin-bottom: 35px !important;
}

.mb-40 {
	margin-bottom: 40px !important;
}

.mt-0 {
	margin-top: 0 !important;
}

.mt-10 {
	margin-top: 10px !important;
}

.mt-16 {
	margin-top: 16px !important;
}

.mt-20 {
	margin-top: 20px !important;
}

.mt-24 {
	margin-top: 24px !important;
}

.mt-30 {
	margin-top: 30px !important;
}

.mt-35 {
	margin-top: 35px !important;
}

.mt-40 {
	margin-top: 40px !important;
}

.mt-50 {
	margin-top: 50px !important;
}

.text-center {
	text-align: center;
}

.text-left {
	text-align: left !important;
}

.justify-content-center {
	justify-content: center !important;
}

.justify-content-start {
	justify-content: flex-start !important;
}

.font-weight-semibold {
	font-weight: 600 !important;
}

.font-weight-medium {
	font-weight: 500 !important;
}
