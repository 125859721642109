//@import 'react-phone-input-2/lib/style.css';
@import 'react-phone-input-2/lib/material.css';
@import 'custom/index';

@import 'global/global-fonts';
@import 'global/global-scaffolding';

@import 'blocks/tabs-button/index';
@import 'variables';
@import 'helpers';

@import 'blocks/status/status';
@import 'blocks/table-body/table-body';
@import 'blocks/pagination-block/pagination-block';
@import 'blocks/title-block-button/title-block-button';
@import 'blocks/sidebar-nav/sidebar-nav';
@import 'blocks/deposit-accounts/deposit-accounts';
@import 'blocks/table-filter/table-filter';
@import 'blocks/select/select';
@import 'blocks/pagination/pagination';
@import 'blocks/user-managment/user-managment';
@import 'blocks/table/table';

@import 'blocks/account-details/account-details';
@import 'blocks/account-header/account-header';
@import 'blocks/beneficiaries/beneficiaries';
@import 'blocks/item-select/item-select';
@import 'blocks/switch/switch';

@import 'blocks/title/title';
@import 'blocks/main-content/main-content';
@import 'blocks/form/form';
@import 'blocks/input/input';
@import 'blocks/loading/loading';
@import 'blocks/popup/popup';
@import 'blocks/coin/coin';
@import 'blocks/toastify/toastify';
@import 'blocks/login/login';
@import 'blocks/login-banner/login-banner';
@import 'blocks/login-form/login-form';
@import 'blocks/login-section/login-section';
@import 'blocks/input-item/input-item';
@import 'blocks/login-form-item/login-form-item';
@import 'blocks/footer/footer';
@import 'blocks/footer-nav/footer-nav';
@import 'blocks/info-section/info-section';
@import 'blocks/accordion/accordion';
@import 'blocks/react-tel-input-wrapper/react-tel-input-wrapper';
@import 'blocks/create-account-progress/create-account-progress';
@import 'blocks/settings-2fa/settings-2fa';
@import 'blocks/settings-2fa-app/settings-2fa-app';
@import 'blocks/settings-2fa-qr-code/settings-2fa-qr-code';
@import 'blocks/tooltip/tooltip';
@import 'blocks/btn/btn';
@import 'blocks/date-picker/date-picker';
@import 'blocks/filters/filters';
@import 'blocks/main-section/main-section';
@import 'blocks/table-users/table-users';
@import 'blocks/create-admin/create-admin';
@import 'blocks/tabs-buttons-nav/tabs-buttons-nav';
@import 'blocks/currencyButtonFile/currencyButtonFile';
@import 'blocks/table-walletBalance/table-walletBalance';
@import 'blocks/totalBalances/totalBalances';
@import 'blocks/wallet-content-balance/wallet-content-balance';
@import 'blocks/wallet-content-balance-item/wallet-content-balance-item';
@import 'blocks/fileButton/fileButton';
@import 'blocks/trade-size/trade-size';
@import 'blocks/popup-search/popup-search';
@import 'blocks/add-account-search-list/add-account-search-list';
@import 'blocks/tabs-account/tabs-account';
@import 'blocks/user-management-item/user-management-item';
@import 'blocks/user-management-balance/user-management-balance';
@import 'blocks/create-account-item/create-account-item';
@import 'blocks/table-panel/table-panel';
@import 'blocks/copy-button/copy-button';
@import 'blocks/wallet-operations-header/wallet-operations-header';
@import 'blocks/wallet-operations-header-wrap/wallet-operations-header-wrap';
@import 'blocks/detailed-view/detailed-view';
@import 'blocks/input-file/input-file';
@import 'blocks/editor/editor';
@import 'blocks/associated-accounts/associated-accounts';
@import 'blocks/associated-accounts_main/associated-accounts_main';
@import 'blocks/checkbox-role/checkbox-role';
@import 'blocks/message/message';
@import 'blocks/maintenance/maintenance';
@import 'blocks/custom-checkbox/custom-checkbox';

