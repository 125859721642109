:root {
	--transition-base: 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	--font-sans: 'Twemoji Country Flags', 'Euclid Circular A', sans-serif;
	--text-secondary: #777e90;
	--text-additional: #bbbec7;
	--white500: #ffffff;
	--additional-white: #ffffff;
	--gray100: #e4e4e4;
	--gray300: #fafafa;
	--gray400: #f2f2f2;
	--gray600: #636363;
	--gray700: #434343;
	--gray800: #141414;
	--blue700: #0071ab;
	--blue600: #212c63;
	--blue600-hover: #226a99;
	--blue400: #0071ab;
	--blue300: #009feb;
	--blue300-hover: #00a8ff;
	--blue200: #a8dbf9;
	--blue100: #d4edfc;
	--blue100-hover: #e1f1fd;
	--additional-error: #fe0000;
	--additional-warning: #ffb500;
	--additional-green: #007f00;
	--primary-blue-600: #212c63;
	--primary-blue-500: #4a4e82;
	--primary-blue-400: #0071ab;
	--primary-blue-200: #a8dbf9;
	--primary-blue-100: #d4edfc;
	--primary-blue-50: #f1f7fc;
	--greyscale-grey-10: #fafafa;
	--greyscale-grey-100: #e4e4e4;
	--greyscale-grey-300: #b5bacd;
	--greyscale-grey-800: #141414;
	--greyscale-grey-700: #434343;
	--greyscale-grey-600: #636363;
	--greyscale-grey-400: #949494;
	--greyscale-grey-200: #bfbfbf;
	--greyscale-grey-500: #777e90;
	--greyscale-grey-50: #f2f2f2;
	--transparent-red: #feeceb;
	--transparent-green: #f0f7f0;
	--transparent-yellow: #fffbf0;
	--Greyscale-Grey-800: #141414;
	--Greyscale-Grey-600: #636363;
	--Border-Line: #e6e8ec;
	--animate-duration: 300ms;
}
