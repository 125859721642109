.filters {
	&--deposit-wallet-user {
		margin: 0;

		.table-filter {
			margin: 0;
			justify-content: flex-start;
			gap: 8px;
		}

		.filter {
			margin-right: 0;
		}
	}

	.filter {
		&--right {
			margin-left: auto;
		}
	}

	&__download-buttons-wrap {
		display: flex;
		justify-content: flex-end;
		gap: 10px;
	}

	&__search-block {
		border-radius: 14px;
		border: 1px solid var(--greyscale-grey-100);
		background: #fff;
		background: var(--additional-white);
		min-width: 100%;
		min-height: 64px;
		padding: 10px;
		justify-content: space-between;
		margin: 0;
		gap: 8px;

		&--bank-accounts-with-select {
		}

		.filter {
			background: #fafafa;
			padding: 5px 10px;
			border-radius: 8px;

			.search-input {
				height: 30px;
				display: flex;
				align-items: center;

				.select__current {
					padding-left: 10px;
					padding-right: 10px;
					min-width: 120px;
					font-size: 14px;
					font-weight: 500;
					line-height: 18px;
					letter-spacing: 0;
					text-align: left;
					white-space: nowrap;
				}

				.input {
					margin-left: 10px;
				}

				.input-wrapper {
					input {
						height: 28px;
						border: 1px solid var(--additional-white);
						font-size: 12px;
						color: #000;
						width: 270px;
						padding: 0 10px;
						border-radius: 6px;
						background: #fff;
						background: var(--additional-white);
						transition: border-color var(--transition-base);

						&:focus {
							border-color: var(--primary-blue-200);
						}

						&::placeholder {
							font-size: 12px;
							color: var(--greyscale-grey-200);
							font-weight: 300;
							line-height: normal;
							letter-spacing: 0;
							text-align: left;
						}
					}
				}
			}

			.select--type4 {
				height: 28px;
				border-radius: 6px;
				background: var(--additional-white) !important;
				border: 1px solid var(--additional-white);
				position: relative;
				transition: border-color var(--transition-base);

				&.active {
					border-color: var(--primary-blue-200);
				}

				.select__drop {
					display: block;
					padding: 2px;

					ul > li > button {
						padding: 5px 10px;
						border-radius: 4px;
						color: var(--greyscale-grey-500);
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 160%;
						background: transparent;

						&:hover {
							background: var(--primary-blue-50);
							color: var(--greyscale-grey-800);
							font-weight: 500;
						}
					}
				}
			}
		}
	}
}

.active-trade_filters {
	justify-content: space-between;
	padding: 10px;
	flex-direction: row;
	display: flex;
	align-items: center;
	border-radius: 14px;
	border: 1px solid var(--greyscale-grey-100);
	background: var(--additional-white);
	margin-bottom: 10px;
	flex-wrap: wrap;

	@media screen and (max-width: 1199px) {
		justify-content: center;
		gap: 10px;
	}

	p {
		color: var(--primary-blue-600);
		font-size: 14px;
	}

	&--left {
		display: flex;
		flex-direction: row;
		gap: 10px;
	}

	.select {
		.select__current {
			align-items: center;
			justify-content: space-between;
			color: var(--greyscale-grey-800);
			font-size: 14px;
			font-weight: 500 !important;
			background: var(--additional-white);
			border: 1px solid var(--additional-white);
			border-radius: 8px;
		}

		&--date {
			.select__current {
				gap: 10px;
				justify-content: flex-start;
			}
		}
	}

	.select__drop {
		min-width: 110px;
		padding: 2px;
		border-radius: 6px;

		ul > li > button {
			padding: 5px 10px;
			color: var(--greyscale-grey-500);
			font-size: 14px;
			font-style: normal;
			font-weight: 400 !important;
			line-height: 160%;
			min-height: 32px;
			display: flex;
			align-items: center;
			flex-direction: row;
			gap: 8px;
			border-radius: 4px;

			&:hover {
				background: var(--primary-blue-50);
				color: var(--greyscale-grey-800);
				font-weight: 500 !important;
			}

			img {
				width: 18px;
				min-width: 18px;
				height: 18px;
				min-height: 18px;
			}
		}

		.select__drop-item {
			background-color: var(--additional-white);

			ul li {
				&:hover {
					color: var(--greyscale-grey-800);
					background: var(--primary-blue-50);
					font-weight: 500 !important;
				}
			}

			button {
				background-color: var(--additional-white);

				&:hover {
					background: var(--primary-blue-50);
				}
			}
		}

		&--date-picker {
			width: 100% !important;
			min-width: 264px;
		}
	}

	&--date,
	&--currency,
	&--status,
	&--search {
		display: flex;
		flex-direction: row;
		align-items: center;
		border-radius: 8px;
		background: var(--greyscale-grey-10);
		gap: 10px;
		height: 38px;
		padding: 6px 10px;
	}

	&--date {
		padding: 0;
		background: none;
		border: 0;

		.btn--date {
			margin-left: 10px;
		}

		.select {
			gap: 10px;
			width: 266px;
			height: 100%;
			align-items: center;
			padding: 0;
			border-radius: 8px;
			background: var(--greyscale-grey-10);
			border: 1px solid var(--greyscale-grey-10);
			transition: border-color var(--transition-base), background-color var(--transition-base);

			&.active {
				background-color: #fff;
				border-color: var(--primary-blue-200);
			}

			.select__current {
				color: var(--primary-blue-600);
				font-weight: 400 !important;
				height: 100%;
				background: transparent;
			}
		}

		p {
			font-size: 14px;
			color: var(--greyscale-grey-800);
			display: flex;
			font-weight: 500;
		}

		span {
			white-space: pre;
		}
		.react-datepicker {
			justify-content: center;
		}
	}

	&--currency {
		.select {
			width: 110px;
			padding: 0;
			align-items: center;

			&.active {
				border: 0;

				.select__current {
					border-color: var(--primary-blue-200);
					border-radius: 6px;
				}
			}

			.select__current {
				padding: 2px 10px;
				min-height: 26px;
			}
		}

		.selected__current_text {
			display: flex;
			gap: 8px;
			align-items: center;

			img {
				width: 18px;
				min-width: 18px;
				height: 18px;
				min-height: 18px;
			}
		}
	}

	&--status {
		.select {
			width: 130px;
			padding: 0;
			align-items: center;

			&.active {
				border: 0;

				.select__current {
					border-color: var(--primary-blue-200);
					border-radius: 6px;
				}
			}

			.select__current {
				padding: 2px 6px;
				min-height: 26px;
			}
		}
	}

	&--search {
		padding: 6px 10px;
		p {
			color: var(--greyscale-grey-800);
		}

		.select {
			border: 0;
			border-radius: 0;
			padding: 0;
			height: auto;
			background: none;

			&.active {
				border: 0;

				.select__current {
					border-color: var(--primary-blue-200);
				}
			}

			.select__current {
				border: 1px solid #fff;
				width: 110px;
				padding: 2px 8px;
				min-height: 28px;
				border-radius: 6px;
				overflow: hidden;
			}

			.select__current-text {
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}

		.active {
			border: 1px solid var(--primary-blue-200);
		}

		.search-input {
			display: flex;
			align-items: center;
			gap: 10px;
		}

		.input {
			margin-bottom: 0;
			min-width: 120px;
			max-width: 160px;

			.input-item {
				height: 28px;
				color: var(--greyscale-grey-800);
				border: 1px solid var(--additional-white);
				background-color: var(--additional-white);
				border-radius: 6px;
				transition: border-color var(--transition-base);

				&::placeholder {
					color: var(--greyscale-grey-400);
					font-size: 12px;
				}

				&:placeholder-shown {
					text-overflow: ellipsis;
				}

				&:focus {
					border: 1px solid var(--primary-blue-200);
				}
			}

			.input-icon--right {
				right: 10px;
			}
		}

		.phone-number-input {
			grid-template-columns: 1fr 1.8fr;
			align-items: center;
		}

		.phone-number-input-trade {
			display: flex;
			align-items: center;
			gap: 10px;
			p {
				min-width: 40px;
			}
		}
	}
	&--wrapper {
		display: flex;
		gap: 10px;
		.select--date {
			width: 275px;
		}
		.active-trade_filters--search {
		}
	}
}

.reset_trade-filters {
	display: flex;
	color: var(--primary-blue-400);
	gap: 6px;
	align-items: center;
	font-weight: 500;
	padding: 6px;
}

.active-message_filters {
	.active-trade_filters--search {
		.select .select__current {
			width: 160px;
		}
		.input {
			max-width: 220px;
		}
	}
}

.message-form_filters {
	display: flex;
	gap: 24px;
	padding: 10px 24px;
	flex-direction: row;
	align-items: center;
	border-radius: 14px;
	border: 1px solid var(--greyscale-grey-100);
	background: var(--additional-white);
	margin-bottom: 10px;
	flex-wrap: nowrap;
	justify-content: space-between;
	@media screen and (max-width: 1180px) {
		flex-wrap: wrap;
	}

	.search-input-wrapper {
		width: 65%;

		@media screen and (min-width: 1700px) {
			width: 73%;
		}
		@media screen and (min-width: 1500px) and (max-width: 1700px) {
			width: 70%;
		}
		@media screen and (max-width: 1440px) {
			width: 60%;
		}
		@media screen and (max-width: 1320px) {
			width: 50%;
		}
	}
	.input-wrapper {
		display: flex;
		gap: 10px;
		background-color: var(--greyscale-grey-10);
		padding: 6px 10px;
		align-items: center;

		.select {
			min-width: 170px;
			height: 28px;
			border: none;
			border-radius: 6px;
		}
		.input-item {
			height: 28px;
			border: none;
			border-radius: 6px;
		}
	}
}

