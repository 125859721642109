@keyframes popupOpen {
	0% {
		transform: scale(1) translateY(0px);
		opacity: 0;
		box-shadow: 0 0 0 rgba(241, 241, 241, 0);
	}
	1% {
		transform: scale(0.96) translateY(10px);
		opacity: 0;
		box-shadow: 0 0 0 rgba(241, 241, 241, 0);
	}
	100% {
		transform: scale(1) translateY(0px);
		opacity: 1;
		box-shadow: 0 0 500px rgba(241, 241, 241, 0);
	}
}

@keyframes popupClose {
	0% {
		transform: scale(1) translateY(0px);
		opacity: 1;
		box-shadow: 0 0 500px rgba(241, 241, 241, 0);
	}
	1% {
		transform: scale(1) translateY(0px);
		opacity: 1;
		box-shadow: 0 0 0 rgba(241, 241, 241, 0);
	}
	100% {
		transform: scale(0.96) translateY(0px);
		opacity: 0;
		box-shadow: 0 0 0 rgba(241, 241, 241, 0);
	}
}

@keyframes transition {
	0% {
		opacity: 0;
	}
	1% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.popup-window {
	position: static;
}

.popup-content {
	padding: 15px;
	animation: popupOpen 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;

	&.is-closing {
		animation: popupClose 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
	}
}

.popup-soc {
	display: flex;
	justify-content: center;
	gap: 16px;
}

.popup-table {
	border-radius: 4px;
	background: var(--greyscale-grey-10);
	padding: 10px;
	display: flex;
	flex-direction: column;
	gap: 16px;

	&__tr {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__td {
		color: var(--greyscale-grey-500);
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;

		span {
			color: var(--greyscale-grey-800);
			font-weight: 500;
			line-height: 180%; /* 21.6px */
		}
	}
}

.popup {
	padding: 24px;
	width: 100%;
	border-radius: 10px;
	animation: transition 300ms ease-in-out forwards;

	@media only screen and (min-width: 576px) {
		width: 414px;
	}

	&--width-480 {
		@media only screen and (min-width: 576px) {
			width: 480px;
		}
	}

	&--width-520 {
		@media only screen and (min-width: 576px) {
			width: 520px;
		}
	}

	&__close-btn {
		background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.8763 13.9974L10.0013 10.8724L13.1263 13.9974L14.0013 13.1224L10.8763 9.9974L14.0013 6.8724L13.1263 5.9974L10.0013 9.1224L6.8763 5.9974L6.0013 6.8724L9.1263 9.9974L6.0013 13.1224L6.8763 13.9974ZM10.0013 18.3307C8.86241 18.3307 7.78603 18.112 6.77214 17.6745C5.75825 17.237 4.87283 16.6398 4.11589 15.8828C3.35894 15.1259 2.76172 14.2405 2.32422 13.2266C1.88672 12.2127 1.66797 11.1363 1.66797 9.9974C1.66797 8.84462 1.88672 7.76129 2.32422 6.7474C2.76172 5.73351 3.35894 4.85156 4.11589 4.10156C4.87283 3.35156 5.75825 2.75781 6.77214 2.32031C7.78603 1.88281 8.86241 1.66406 10.0013 1.66406C11.1541 1.66406 12.2374 1.88281 13.2513 2.32031C14.2652 2.75781 15.1471 3.35156 15.8971 4.10156C16.6471 4.85156 17.2409 5.73351 17.6784 6.7474C18.1159 7.76129 18.3346 8.84462 18.3346 9.9974C18.3346 11.1363 18.1159 12.2127 17.6784 13.2266C17.2409 14.2405 16.6471 15.1259 15.8971 15.8828C15.1471 16.6398 14.2652 17.237 13.2513 17.6745C12.2374 18.112 11.1541 18.3307 10.0013 18.3307ZM10.0013 17.0807C11.9735 17.0807 13.6471 16.3898 15.0221 15.0078C16.3971 13.6259 17.0846 11.9557 17.0846 9.9974C17.0846 8.02517 16.3971 6.35156 15.0221 4.97656C13.6471 3.60156 11.9735 2.91406 10.0013 2.91406C8.04297 2.91406 6.37283 3.60156 4.99089 4.97656C3.60894 6.35156 2.91797 8.02517 2.91797 9.9974C2.91797 11.9557 3.60894 13.6259 4.99089 15.0078C6.37283 16.3898 8.04297 17.0807 10.0013 17.0807Z' fill='%230071AB'/%3E%3C/svg%3E%0A");
		display: block;
		width: 20px;
		height: 20px;
		position: absolute;
		top: 16px;
		right: 16px;
		cursor: pointer;
	}

	.popup-header {
		&--mb-20 {
			margin-bottom: 20px;
		}

		&__title {
			font-size: 20px;
			font-weight: 600;
			color: var(--gray800);

			&.red {
				color: var(--additional-error);
			}

			&.success {
				color: var(--blue700);
				font-size: 24px;
			}

			+ .popup-header__title {
				margin-top: 20px;
				color: var(--blue700);
				text-align: center;
				font-size: 24px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
			}
		}
	}

	.popup-body {
		margin-top: 20px;
		textarea {
			min-height: 100px;
			padding: 8px 16px;
			&::-webkit-scrollbar {
				width: 4px;
			}
			&::-webkit-scrollbar-thumb {
				border-radius: 2px;
				background-color: #b6b6b6;
			}
			&::-webkit-scrollbar-track {
				border-radius: 2px;
				margin: 10px 5px 10px 5px;
				background: #eeeeee;
			}
		}
		.input-wrapper {
		}
	}

	.popup-icon {
		margin: 20px 0;
		display: flex;
		justify-content: center;
		text-align: center;

		&--type2 {
			margin: 0 0 20px 0;
		}

		&--error {
			position: relative;
			margin-bottom: 20px;
		}
		&--status {
			position: relative;
		}
	}

	.popup-img {
		margin: 0 0 20px 0;
	}

	.popup-text {
		font-weight: 400;
		font-size: 16px;
		line-height: 160%;
		color: var(--gray600);
		text-align: center;

		a,
		a.link {
			color: var(--primary-blue-400) !important;
		}
	}

	.popup-submit {
		margin-top: 24px;
		gap: 10px;

		&--column {
			flex-direction: column;
		}
	}
}

.status-popupImg {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	.outer-circle {
		width: 52px;
		height: 52px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		z-index: 1;
	}

	.inner-circle {
		width: 42px;
		height: 42px;

		border-radius: 50%;
	}
}
.popup-body {
	.block {
		background: transparent;
		.outer-circle {
			background-color: #c1e7fd;
		}
		.inner-circle {
			background-color: #3498db;
		}
	}
	.unblock {
		.outer-circle {
			background-color: var(--transparent-green);
		}
		.inner-circle {
			background-color: var(--additional-green);
		}
	}
	.archive {
		.outer-circle {
			background-color: var(--transparent-red);
		}
		.inner-circle {
			background-color: var(--additional-error);
		}
	}
	&--transaction {
		.search-input-wrapper {
			.select {
				position: absolute;
				top: 6px;
				left: 5px;
				height: 30px;
				width: 173px;
				border-radius: 4px;
				background: var(--primary-blue-50);
			}
			input {
				padding-left: 183px;
				::placeholder {
					font-family: var(--font-sans);
				}
			}
			.input-notify {
				padding-left: 183px;
			}
		}
		.input__name,
		.select-block__name {
			color: var(--greyscale-grey-600);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
		}
		.select {
			border-radius: 10px;
			height: 44px;
			.select__drop {
				.select__drop-item {
					background-color: var(--additional-white);
					ul li {
						:hover {
							color: var(--greyscale-grey-800);
							background: var(--primary-blue-50);
							font-weight: 500;
						}
					}
					button {
						background-color: var(--additional-white);
						:hover {
							background: var(--primary-blue-50);
						}
					}

					.upper__text {
						display: flex;
						flex-direction: column;
						.coin__text-more {
							font-size: 10px;
							margin-left: 0px;
						}
					}
				}
			}
			.coin__text-more {
				font-size: 11px;
			}
			&--add-transaction {
				margin-bottom: 16px;
				.select__current {
					span {
						width: 100%;
						text-align: start;
					}
				}
				.button--size2 {
					margin-top: 10px;
				}
			}
		}
		input,
		textarea {
			color: var(--greyscale-grey-800);
		}
		textarea {
			padding-top: 6px;
			min-height: 120px;
		}
		.input-item--auto-calc input {
			color: var(--greyscale-grey-400);
			font-weight: 400;
		}
		.select__current {
			font-weight: 500;
			font-size: 14px;
			color: var(--greyscale-grey-800);
		}
		.select__current--placeholder {
			color: var(--greyscale-grey-200);
			font-size: 14px;
			font-weight: 400;
			font-family: var(--font-sans);
			text-align: start;
			min-width: fit-content;
		}
		.input-icon svg path {
			stroke: none;
		}
		.react-datepicker {
			&__month-container {
				padding: 8px;
			}
			&__navigation--next {
				right: 0px;
				top: -3px;
			}
			&__navigation--previous {
				left: 0px;
				top: -3px;
			}
			&__header--time {
				padding: 5px 8px;
			}
			&__time-list-item {
				&:hover {
					color: var(--primary-blue-400) !important;
					background: var(--primary-blue-50) !important;
				}
			}
		}
	}
	&--account {
		.select {
			border-radius: 10px;
			height: 44px;
		}
	}
}
.popup-content {
	.popup-body {
		.popup-img {
			svg {
				stroke: white !important;
				position: relative;
				z-index: 1;
			}
		}
	}
}
.popup-img_center {
	width: 100%;
	display: flex;
	justify-content: center;
}
